<template>
    
    <basic-page  class="progetto-dettaglio" v-if="this.ctData && this.ctData.attributes" 
        :pagedata="this.basicpageData" 
        :bannerimg="this.ctData" 
        :fieldname="'field_project_foto'"
        addedclass="progetto-dettaglio white-bg">
        
        <div class="container-espe">
            <div class="info">
                <router-link to="/chi-supportiamo" class="indietro"></router-link>
                <h5 class="title">{{ this.ctData.attributes.title }}</h5>
                <div class="field"
                    :style="fieldStyle(this.ctData.field_project_settore.attributes.drupal_internal__tid)"></div>
            </div>
        </div>


        <div class="container-espe">
            <div class="info">
                <div class="white-tile raccolti">
                    <div class="donated">{{ parseFloat(this.ctData.attributes.field_project_raccolti * 2.00).toFixed(2) }} <span>€</span> </div>
                    <div class="donated-icon"></div>
                    <div class="donation-bar" :style="'--wp: ' + this.percentage + '%;'">
                        <div class="raccolti"></div>
                        <div class="bar"></div>
                        <div class="goal"></div>
                        <div class="label">
                            <span>{{ this.percentage + '%' }}</span> di
                            <span>{{ this.ctData.attributes.field_project_goal + ' €' }}</span>
                        </div>
                    </div>
                </div>

                <div class="organization">
                    <div class="content" v-if="this.ctData.field_project_promotori[0]">
                        <p>Organizzato da</p>
                        <div class="loghi">
                            <div v-for="(image, index) in this.ctData.field_project_promotori" :key="index">
                                <img :src="endpoint + image.attributes.uri.url" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-espe gray-bg">
            <br>
            <div class="white-tile">
                <h3>DECIDI QUANTO DONARE</h3>
                <div class="paypal">
                    <div class="modulo-esperto">
                        <div class="radios">
                            <div class="campo"><input type="radio" name="donazione" v-model="radio_amount" :value="5">5€
                            </div>
                            <div class="campo"><input type="radio" name="donazione" v-model="radio_amount"
                                    :value="10">10€</div>
                            <div class="campo"><input type="radio" name="donazione" v-model="radio_amount"
                                    :value="15">15€</div>
                            <div class="campo"><input type="radio" name="donazione" v-model="radio_amount"
                                    :value="20">20€</div>
                        </div>
                        <p>oppure</p>
                        <div class="campo euro">
                            <input type="number" step=".05" id="campoeuro" v-model="donation_amount"
                                placeholder="inserisci un importo">
                        </div>
                        <br>
                        <div class="button-container" id="paypalbtts"></div>
                    </div>
                </div>
            </div>


        </div>

        <div class="container-espe">
            <h2>IL PROGETTO</h2>
            <p v-if="this.ctData.attributes.field_project_descrizione.value.length>750" v-html="this.readmore
                    ? this.ctData.attributes.field_project_descrizione.value : 
                    (this.ctData.attributes.field_project_descrizione.value.substr(0, 750) + ' ...')"></p>
            <p v-else v-html="this.ctData.attributes.field_project_descrizione.value"></p>
            <div v-if="this.ctData.attributes.field_project_descrizione.value.length>=750" class="button-container"
                style="text-align: left;">
                <button class="btn" style="padding: .5em 1em; max-width: 200px;"
                    @click="this.accordion">{{ this.readmore ? 'Chiudi' : 'Leggi di più'}}</button>
            </div>
            <br>
            <images :imagedata="this.ctData" :fieldname="'field_project_foto'"
                :breakstyles="{'0': 'sm_image', '576': 'md_image', '992': 'lg_image'}"></images>
        </div>

        <div class="container-espe">
            <h2>CHI SIAMO</h2>
            <p v-html="this.ctData.attributes.field_project_chi_siamo.value">
            </p>
        </div>


    </basic-page>

</template>

<script>
    import {
        fetchNodes,
        fetchTaxonomy,
        fetchRoutes
    } from "../../libs/drupalClient.js"
    import {
        fieldColorProject,
        fieldSrcProject
    } from "../../libs/utils.js"
    import BasicPage from "../BasicPage.vue"
    import {
        loadScript
    } from "@paypal/paypal-js"
    import Images from "../../components/Images.vue"

    export default {
        name: 'project',
        components: {
            BasicPage,
            Images
        },
        data: () => {
            return {
                ctData: [],
                basicpageData: {
                    attributes: {
                        title: "",
                        field_home_intro: {}
                    }
                },
                notfound: true,
                donation_amount: 0,
                radio_amount: 5,
                donationData: {
                    PaypalEmail: "",
                    PaypalNome: "",
                    PaypalCognome: "",
                    Donazione: "",
                    ProjectId: "",
                    ProjectName: ""
                },
                filtersData: [],
                readmore: false, 
                ppbutton_added: false
            }
        },
        computed: {
            endpoint() {
                return process.env.VUE_APP_ENDPOINT
            },
            projectphoto() {
                return process.env.VUE_APP_ENDPOINT + '/' + (this.ctData.field_project_foto ? this.ctData
                    .field_project_foto.attributes.uri.url : 'social-image.jpg')
            },
            lang() {
                return this.$store.state.currentLang
            },
            defaultLang() {
                return this.$store.state.defaultLang
            },
            objectid() {
                return this.$route.params.nid
            },
            objecttitle() {
                return this.$route.params.title
            },
            percentage() {
                return Math.round(this.ctData.attributes.field_project_raccolti * 2.00 * 100 / this.ctData.attributes.field_project_goal)
            },
        },
        methods: {
            accordion() {
                this.readmore = !this.readmore
            },
            fieldStyle(image) {
                var f = this.filtersData.find(i => i.attributes.drupal_internal__tid == image)
                var n = f ? f.attributes.name : 'not-found'

                return '--background-color: ' + fieldColorProject(image) + ';' +
                    '--background-image: url(' + fieldSrcProject(image) + ');' +
                    '--field-text: "' + n + '";' +
                    '--field-width: ' + n.length * 9 + 'px;'
            },
            createOrder(data, actions) {
                return actions.order.create({
                    purchase_units: [{
                        amount: {
                            value: this.donation_amount > 0 ? this.donation_amount : this.radio_amount,
                            breakdown: {
                                item_total: {
                                    currency_code: 'EUR',
                                    value: this.donation_amount > 0 ? this.donation_amount : this
                                        .radio_amount
                                }
                            }
                        },
                        items: [{
                            name: this.ctData.attributes.title,
                            description: this.ctData.attributes.drupal_internal__nid,
                            category: "DONATION",
                            unit_amount: {
                                currency_code: 'EUR',
                                value: this.donation_amount > 0 ? this.donation_amount : this
                                    .radio_amount
                            },
                            quantity: '1'
                        }]
                    }]
                })
            },
            onApprove(data, actions) {
                var tot = this
                return actions.order.capture().then(function (orderData) {

                    var url = new URL(process.env.VUE_APP_POSTURL + "/donazione_spontanea");

                    //aggiungo i campi della donazione effettuata per registrare la transazione nel db
                    url.searchParams.append('PaypalNome', orderData.payer.name.given_name)
                    url.searchParams.append('PaypalCognome', orderData.payer.name.surname)
                    url.searchParams.append('PaypalEmail', orderData.payer.email_address)
                    url.searchParams.append('Donazione', orderData.purchase_units[0].amount.value)
                    url.searchParams.append('ProjectId', orderData.purchase_units[0].items[0].description)
                    url.searchParams.append('ProjectName', orderData.purchase_units[0].items[0].name)

                    fetch(url, {
                            method: "POST",
                            body: {},
                            redirect: "follow"
                        })
                        .then(res => {
                            if (res.status == 200) {
                                tot.donationData.PaypalEmail = orderData.payer.email_address
                                tot.donationData.PaypalNome = orderData.payer.name.given_name
                                tot.donationData.PaypalCognome = orderData.payer.name.surname
                                tot.donationData.Donazione = orderData.purchase_units[0].amount.value
                                tot.donationData.ProjectId = orderData.purchase_units[0].items[0]
                                    .description
                                tot.donationData.ProjectName = orderData.purchase_units[0].items[0].name

                                tot.$store.commit('SET_FORM_DATA', {
                                    type: "donazione-spontanea",
                                    name: tot.donationData.PaypalNome,
                                    surname: tot.donationData.PaypalCognome,
                                    email: tot.donationData.PaypalEmail,
                                    donation_amount: tot.donationData.Donazione,
                                    donation_project: tot.donationData.ProjectName,
                                    expert_contact: ""
                                })
                                tot.$router.push('/confirmed')
                            }
                        })
                        .catch(error => {
                            console.log(error)
                        })
                })

            },
            findCT() {
                fetchRoutes().then(res => {
                    //console.log(this.objectid, this.objecttitle, this.lang)
                    res.routes.forEach(r => {
                        //al caricamento della pagina controlla se esiste il ct richiesto
                        //sia con internal_ID che con titolo definito dal back (che finisce in URL)
                        if (parseInt(r.nid) == this.objectid && r.title == this.objecttitle && r.lang ==
                            this.lang) {
                            this.notfound = false
                        }
                    })

                    if (!this.notfound) {
                        //normale fetch al caricamento della pagina
                        //fetch fatto con ID ma solo se esise con quel titolo corrispondente
                        fetchNodes('project', {
                            filters: [{
                                key: 'drupal_internal__nid',
                                value: this.objectid
                            }],
                            include: ['field_project_foto', 'field_project_settore',
                                'field_project_promotori'
                            ]
                        }, this.lang).then(res => {
                            //console.log('res[0]', res[0]);
                            this.ctData = res[0]
                            this.basicpageData.attributes.field_home_intro = res[0].attributes
                                .field_home_intro
                            this.basicpageData.attributes.title = ""
                        })
                    } else {
                        //se non trova la combinazione id+titolo, reindirizza in pagina di errore
                        this.$router.push('/404/not-found')
                    }
                })
            }
        },
        mounted() {
            this.findCT()

            fetchTaxonomy('settore', {}, this.lang).then(res => {
                this.filtersData = res
            })

            //setTimeout(() => {
                //original load paypal script
            //}, 500);
        },
        updated() {
            const vp = this
            !this.ppbutton_added && loadScript({
                //LIVE EXPEROTARY
                "client-id": "AYCU0wAT4EeOVFV2t3u5F8HaWMDOZcYgprf7jkvSQNeGwx7YabPB93ThCeHosIo3EKb4sv4LC1dOxj8P",
                //SANDBOX
                //"client-id": "AfGn9zITTU6yiwCSbWrqdQsJZ4xsTa1EzDb1XPLJvBL92EHJLzTyGpwUN-ZUyjYGv5e7f0pzxbgRl0Ts",
                "currency": "EUR",
                "disable-funding": "credit,card,venmo,sofort,mybank"
            })
            .then((paypal) => {

                vp.ppbutton_added = true

                paypal.Buttons({
                    createOrder: this.createOrder,
                    onApprove: this.onApprove,
                    style: {
                        layout: 'vertical',
                        color: 'gold',
                        shape: 'pill',
                        label: 'donate'
                    }
                })
                .render("#paypalbtts")
                .catch((error) => {
                    vp.ppbutton_added = false
                    console.error("failed to create PayPalButton", error);
                })
            })
            .catch(error => {
                console.error("failed to load the PayPal JS SDK script", error);
            })
        }
    }
</script>

<style lang="scss" scoped>
</style>